/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as e } from "../../chunks/tslib.es6.js";
import { JSONMap as t } from "../../core/jsonMap.js";
import { JSONSupport as o } from "../../core/JSONSupport.js";
import { getMetersPerUnitForSR as s } from "../../core/unitUtils.js";
import { property as r } from "../../core/accessorSupport/decorators/property.js";
import { Integer as i } from "../../core/accessorSupport/ensureType.js";
import "../../core/RandomLCG.js";
import "../../core/has.js";
import { reader as l } from "../../core/accessorSupport/decorators/reader.js";
import { subclass as n } from "../../core/accessorSupport/decorators/subclass.js";
import { writer as p } from "../../core/accessorSupport/decorators/writer.js";
import a from "../../geometry/Point.js";
import c from "../../geometry/SpatialReference.js";
import { create as u } from "../../geometry/support/aaBoundingRect.js";
import { isValid as f, getInfo as h, isGeographic as m, equals as d } from "../../geometry/support/spatialReferenceUtils.js";
import { project as g } from "../../geometry/support/webMercatorUtils.js";
import y from "./LOD.js";
import { TileKey as v } from "./TileKey.js";
var w;
const x = new t({
  PNG: "png",
  PNG8: "png8",
  PNG24: "png24",
  PNG32: "png32",
  JPEG: "jpg",
  JPG: "jpg",
  DIB: "dib",
  TIFF: "tiff",
  EMF: "emf",
  PS: "ps",
  PDF: "pdf",
  GIF: "gif",
  SVG: "svg",
  SVGZ: "svgz",
  Mixed: "mixed",
  MIXED: "mixed",
  LERC: "lerc",
  LERC2D: "lerc2d",
  RAW: "raw",
  pbf: "pbf"
});
let j = w = class extends o {
  static create(e = {}) {
    const {
      resolutionFactor: t = 1,
      scales: o,
      size: r = 256,
      spatialReference: i = c.WebMercator,
      numLODs: l = 24
    } = e;
    if (!f(i)) {
      const e = [];
      if (o) for (let t = 0; t < o.length; t++) {
        const s = o[t];
        e.push(new y({
          level: t,
          scale: s,
          resolution: s
        }));
      } else {
        let t = 5e-4;
        for (let o = l - 1; o >= 0; o--) e.unshift(new y({
          level: o,
          scale: t,
          resolution: t
        })), t *= 2;
      }
      return new w({
        dpi: 96,
        lods: e,
        origin: new a(0, 0, i),
        size: [r, r],
        spatialReference: i
      });
    }
    const n = h(i),
      p = e.origin ? new a({
        x: e.origin.x,
        y: e.origin.y,
        spatialReference: i
      }) : new a(n ? {
        x: n.origin[0],
        y: n.origin[1],
        spatialReference: i
      } : {
        x: 0,
        y: 0,
        spatialReference: i
      }),
      u = 96,
      d = 1 / (s(i) * 39.37 * u),
      g = [];
    if (o) for (let s = 0; s < o.length; s++) {
      const e = o[s],
        t = e * d;
      g.push(new y({
        level: s,
        scale: e,
        resolution: t
      }));
    } else {
      let e = m(i) ? 512 / r * 591657527.5917094 : 256 / r * 591657527.591555;
      const o = Math.ceil(l / t);
      g.push(new y({
        level: 0,
        scale: e,
        resolution: e * d
      }));
      for (let s = 1; s < o; s++) {
        const o = e / 2 ** t,
          r = o * d;
        g.push(new y({
          level: s,
          scale: o,
          resolution: r
        })), e = o;
      }
    }
    return new w({
      dpi: u,
      lods: g,
      origin: p,
      size: [r, r],
      spatialReference: i
    });
  }
  constructor(e) {
    super(e), this.dpi = 96, this.format = null, this.origin = null, this.size = null, this.spatialReference = null;
  }
  get isWrappable() {
    const {
      spatialReference: e,
      origin: t
    } = this;
    if (e && t) {
      const o = h(e);
      return e.isWrappable && !!o && Math.abs(o.origin[0] - t.x) <= o.dx;
    }
    return !1;
  }
  readOrigin(e, t) {
    return a.fromJSON({
      spatialReference: t.spatialReference,
      ...e
    });
  }
  set lods(e) {
    let t = 0,
      o = 0;
    const s = [],
      r = this._levelToLOD = {};
    e && (t = -1 / 0, o = 1 / 0, e.forEach(e => {
      s.push(e.scale), t = e.scale > t ? e.scale : t, o = e.scale < o ? e.scale : o, r[e.level] = e;
    })), this._set("scales", s), this._set("lods", e), this._initializeUpsampleLevels();
  }
  readSize(e, t) {
    return [t.cols, t.rows];
  }
  writeSize(e, t) {
    t.cols = e[0], t.rows = e[1];
  }
  zoomToScale(e) {
    const t = this.scales;
    if (e <= 0) return t[0];
    if (e >= t.length - 1) return t[t.length - 1];
    const o = Math.floor(e),
      s = o + 1;
    return t[o] / (t[o] / t[s]) ** (e - o);
  }
  scaleToZoom(e) {
    const t = this.scales,
      o = t.length - 1;
    let s = 0;
    for (; s < o; s++) {
      const o = t[s],
        r = t[s + 1];
      if (o <= e) return s;
      if (r === e) return s + 1;
      if (o > e && r < e) return s + Math.log(o / e) / Math.log(o / r);
    }
    return s;
  }
  tileAt(e, t, o, s) {
    const r = this.lodAt(e);
    if (!r) return null;
    let i, l;
    if ("number" == typeof t) i = t, l = o;else if (d(t.spatialReference, this.spatialReference)) i = t.x, l = t.y, s = o;else {
      const e = g(t, this.spatialReference);
      if (null == e) return null;
      i = e.x, l = e.y, s = o;
    }
    const n = r.resolution * this.size[0],
      p = r.resolution * this.size[1];
    return s || (s = new v(null, 0, 0, 0, u())), s.level = e, s.row = Math.floor((this.origin.y - l) / p + .001), s.col = Math.floor((i - this.origin.x) / n + .001), this.updateTileInfo(s), s;
  }
  updateTileInfo(e, t = w.ExtrapolateOptions.NONE) {
    let o = this.lodAt(e.level);
    if (!o && t === w.ExtrapolateOptions.POWER_OF_TWO) {
      const t = this.lods[this.lods.length - 1];
      t.level < e.level && (o = t);
    }
    if (!o) return;
    const s = e.level - o.level,
      r = o.resolution * this.size[0] / 2 ** s,
      i = o.resolution * this.size[1] / 2 ** s;
    e.id = `${e.level}/${e.row}/${e.col}`, e.extent || (e.extent = u()), e.extent[0] = this.origin.x + e.col * r, e.extent[1] = this.origin.y - (e.row + 1) * i, e.extent[2] = e.extent[0] + r, e.extent[3] = e.extent[1] + i;
  }
  upsampleTile(e) {
    const t = this._upsampleLevels[e.level];
    return !(!t || -1 === t.parentLevel) && (e.level = t.parentLevel, e.row = Math.floor(e.row / t.factor + .001), e.col = Math.floor(e.col / t.factor + .001), this.updateTileInfo(e), !0);
  }
  getTileBounds(e, t) {
    const o = this.lodAt(t.level);
    if (null == o) return null;
    const {
        resolution: s
      } = o,
      r = s * this.size[0],
      i = s * this.size[1];
    return e[0] = this.origin.x + t.col * r, e[1] = this.origin.y - (t.row + 1) * i, e[2] = e[0] + r, e[3] = e[1] + i, e;
  }
  lodAt(e) {
    return this._levelToLOD?.[e] ?? null;
  }
  clone() {
    return w.fromJSON(this.write({}));
  }
  getCompatibleForVTL(e) {
    if (this.size[0] !== this.size[1] || 256 === this.size[0] && 512 === e) return null;
    const t = (512 === this.size[0] && 256 === e ? -1 : 0) + (this.spatialReference.isGeographic ? 1 : 0);
    if (this.size[0] === e && 0 === t) return this;
    const o = [],
      s = this.lods.length - t;
    for (let r = 0; r < s; r++) {
      const e = r + t,
        {
          scale: s,
          resolution: i
        } = e >= 0 ? this.lods[e] : {
          scale: 2 * this.lods[0].scale,
          resolution: 2 * this.lods[0].resolution
        };
      o.push(new y({
        level: r,
        scale: s,
        resolution: i
      }));
    }
    return new w({
      size: [e, e],
      dpi: this.dpi,
      format: this.format,
      compressionQuality: this.compressionQuality,
      origin: this.origin,
      spatialReference: this.spatialReference,
      lods: o
    });
  }
  _initializeUpsampleLevels() {
    const e = this.lods;
    this._upsampleLevels = [];
    let t = null;
    for (let o = 0; o < e.length; o++) {
      const s = e[o];
      this._upsampleLevels[s.level] = {
        parentLevel: t ? t.level : -1,
        factor: t ? t.resolution / s.resolution : 0
      }, t = s;
    }
  }
};
e([r({
  type: Number,
  json: {
    write: !0
  }
})], j.prototype, "compressionQuality", void 0), e([r({
  type: Number,
  json: {
    write: !0
  }
})], j.prototype, "dpi", void 0), e([r({
  type: String,
  json: {
    read: x.read,
    write: x.write,
    origins: {
      "web-scene": {
        read: !1,
        write: !1
      }
    }
  }
})], j.prototype, "format", void 0), e([r({
  readOnly: !0
})], j.prototype, "isWrappable", null), e([r({
  type: a,
  json: {
    write: !0
  }
})], j.prototype, "origin", void 0), e([l("origin")], j.prototype, "readOrigin", null), e([r({
  type: [y],
  value: null,
  json: {
    write: !0
  }
})], j.prototype, "lods", null), e([r({
  readOnly: !0
})], j.prototype, "scales", void 0), e([r({
  cast: e => Array.isArray(e) ? e : "number" == typeof e ? [e, e] : [256, 256]
})], j.prototype, "size", void 0), e([l("size", ["rows", "cols"])], j.prototype, "readSize", null), e([p("size", {
  cols: {
    type: i
  },
  rows: {
    type: i
  }
})], j.prototype, "writeSize", null), e([r({
  type: c,
  json: {
    write: !0
  }
})], j.prototype, "spatialReference", void 0), j = w = e([n("esri.layers.support.TileInfo")], j), function (e) {
  var t;
  (t = e.ExtrapolateOptions || (e.ExtrapolateOptions = {}))[t.NONE = 0] = "NONE", t[t.POWER_OF_TWO = 1] = "POWER_OF_TWO";
}(j || (j = {}));
const z = j;
export { z as default };