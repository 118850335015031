/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.30/esri/copyright.txt for details.
*/
import { _ as e } from "../../chunks/tslib.es6.js";
import { JSONSupport as o } from "../../core/JSONSupport.js";
import { property as r } from "../../core/accessorSupport/decorators/property.js";
import { Integer as t } from "../../core/accessorSupport/ensureType.js";
import "../../core/RandomLCG.js";
import "../../core/has.js";
import { subclass as s } from "../../core/accessorSupport/decorators/subclass.js";
var i;
let l = i = class extends o {
  constructor(e) {
    super(e), this.cols = null, this.level = 0, this.levelValue = null, this.origin = null, this.resolution = 0, this.rows = null, this.scale = 0;
  }
  clone() {
    return new i({
      cols: this.cols,
      level: this.level,
      levelValue: this.levelValue,
      resolution: this.resolution,
      rows: this.rows,
      scale: this.scale
    });
  }
};
e([r({
  json: {
    write: !0,
    origins: {
      "web-document": {
        read: !1,
        write: !1
      },
      "portal-item": {
        read: !1,
        write: !1
      }
    }
  }
})], l.prototype, "cols", void 0), e([r({
  type: t,
  json: {
    write: !0
  }
})], l.prototype, "level", void 0), e([r({
  type: String,
  json: {
    write: !0
  }
})], l.prototype, "levelValue", void 0), e([r({
  json: {
    write: !0,
    origins: {
      "web-document": {
        read: !1,
        write: !1
      },
      "portal-item": {
        read: !1,
        write: !1
      }
    }
  }
})], l.prototype, "origin", void 0), e([r({
  type: Number,
  json: {
    write: !0
  }
})], l.prototype, "resolution", void 0), e([r({
  json: {
    write: !0,
    origins: {
      "web-document": {
        read: !1,
        write: !1
      },
      "portal-item": {
        read: !1,
        write: !1
      }
    }
  }
})], l.prototype, "rows", void 0), e([r({
  type: Number,
  json: {
    write: !0
  }
})], l.prototype, "scale", void 0), l = i = e([s("esri.layers.support.LOD")], l);
const p = l;
export { p as default };